<template>
  <div id="users">
    <el-form :rules="addFormRules" v-loading="loading" :label-position="labelPosition" label-width="80px"
             :model="addForm" ref="addFormRef">
      <el-form-item label="云端Url" prop="url">
        <el-input style="width: 400px" v-model="addForm.url"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input style="width: 400px" v-model="addForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码"  prop="password"  >
        <el-input style="width: 400px" v-model="addForm.password" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="addUrl">测试</el-button>
        <el-button @click="resetForm('ruleForm')">设置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: '',
      addForm: {
        url: '',
        username: '',
        password: '',
        softMarketUrl: ''
      },
      loading: true,
      addFormRules: {
        url: [
          {required: true, message: '请输入url', trigger: 'blur'},
          {min: 1, trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入username', trigger: 'blur'},
          {min: 1, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入password', trigger: 'blur'},
          {min: 1, trigger: 'blur'}
        ],
      },
    };
  },
  methods: {

    /**
     * 测试
     */
    async addUrl() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          const {data: res} = await this.$http.post("/factorymng/testlogin", this.addForm);
          if (res.success) {
            this.$notify.success({
              title: '测试成功',
            });
          } else {
            return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
          }

        }
      });
    },

    async getSetting () {
      const {data: res} = await this.$http.get("/factorymng/setting", this.addForm);
          if (res.success) {
            this.addForm = res.data;
            this.$set(this.addForm, "url", res.data.softMarketUrl)
            localStorage.setItem("factoryId",this.addForm.id)
          } else {
            return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
          }
    },

    /**
     * 登录
     */
    async resetForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return;
        } else {
          const {data: res} = await this.$http.post("/factorymng/login", this.addForm);
          if (res.success) {
            this.$notify.success({
              title: '设置成功',
            });
          } else {
            return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
          }
        }
      });
    },
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
    this.getSetting()
  }
}
</script>

<style scoped>

</style>
